import { TabLangs } from 'interfaces'

export const LANG_DROPDOWN_ITEMS: TabLangs[] = [
	{ label: 'ENG', value: 'en' },
	{ label: 'HEB', value: 'he' }
]

export const CAROUSEL_ARROW_TYPE: { [key: string]: string } = {
	PREV: 'PREV',
	NEXT: 'NEXT'
}

export const ITEMS_TO_SHOW = 3

export const THEME_COLORS = {
	black: '#000000',
	orange: '#FF6B26',
	white: '#FFFFFF',
	teal: '#CFE6FA',
	greenPale: '#B8E8CC',
	red: '#FF636B',
	green: '#5CD4AB',
	whiteAmika: '#FEFAF0',
	cyan: '#FD5FDA',
	gray: '#C4C4C4'
}

export const DEFAULT_ITEMS_TO_SHOW = 4

export const PRODUCT_PAGE_LABELS = {
	he: {
		WORKS_WELL_WITH: 'יכול לעניין אותך גם',
		HOW_TO: 'כיצד נשתמש',
		LEARN_MORE: 'ספרו לי עוד'
	},
	en: {
		WORKS_WELL_WITH: 'works well with',
		HOW_TO: 'how to',
		LEARN_MORE: 'Learn More'
	}
}

export const PRODUCT_ICONS_LABELS = {
	he: {
		cruelty_safe: ' ללא אכזריות',
		color_safe: ' מתאים לשיער צבוע',
		keratin_safe: ' מתאים לשיער שעבר החלקה',
		sulfate_free: ' ללא סולפטים',
		paraben_free: ' ללא פרבאנים',
		no_mineral_oil: ' ללא שמן מינרלי'
	},
	en: {
		cruelty_safe: 'Cruelty safe',
		color_safe: 'Color Safe',
		keratin_safe: 'Keratin Safe',
		sulfate_free: 'Sulfate Free',
		paraben_free: 'Pareben Free',
		no_mineral_oil: 'No Mineral Oil'
	}
}
