import { createContext, useEffect, useState } from 'react'
import { Lang, LangContextInterface, ReactChildren } from 'interfaces'
import { useEnv } from '@perchinteractiveinc/react-template-lb'
import { LANG_DROPDOWN_ITEMS } from 'utils'

export const LangContext = createContext({} as LangContextInterface)

export const LangProvider = ({ children }: ReactChildren) => {
	const defaultLang = useEnv('GATSBY_LANG', 'he') as Lang
	const [lang, setLang] = useState<Lang>(defaultLang)
	useEffect(() => {
		// There was a failure to the initial language set, so let's explicitly set it
		setTimeout(() => {
			setLang(LANG_DROPDOWN_ITEMS[1].value)
		}, 500)
	}, [setLang])

	return (
		<LangContext.Provider value={{ lang, setLang }}>
			{children}
		</LangContext.Provider>
	)
}
