import { extendTheme } from '@chakra-ui/react'
import { THEME_COLORS } from 'utils'

const defaultTheme = extendTheme({
	colors: THEME_COLORS,
	styles: {
		global: {
			h1: {
				fontWeight: '600',
				fontSize: ' 90px',
				variants: {
					weird: {
						color: 'green'
					}
				}
			}
		}
	},
	components: {
		Text: {
			variants: {
				title: {
					fontSize: '55px',
					fontWeight: 400,
					fontFamily: 'FuturaNDDemibold',
					color: 'black',
					lineHeight: '64.45px'
				},
				subtitle: {
					fontSize: '40px',

					fontFamily: 'FuturaND-Medium',
					color: 'black'
				},
				product: {
					fontSize: '40px',
					fontWeight: 200,
					fontFamily: 'FuturaND',
					color: 'black',
					wordWrap: 'break-word',
					lineHeight: '50px'
				},
				paragraphSm: {
					fontSize: '18px',
					lineHeight: '23px',
					fontFamily: 'FuturaNDBook'
				},
				paragraphMd: {
					fontSize: '23px',
					lineHeight: '29px',
					fontFamily: 'FuturaNDBook'
				},
				paragraphLg: {
					fontSize: '35px',
					lineHeight: '45px',
					fontFamily: 'FuturaNDBook',
					fontWeight: 400
				}
			}
		},

		Button: {
			variants: {
				outlinedLg: {
					border: '3px solid black',
					borderRadius: '39px',
					padding: '10px 50px',
					fontSize: '55px'
				}
			}
		},

		Heading: {
			variants: {
				special: {
					color: 'blue'
				}
			}
		}
	}
})

export default defaultTheme
