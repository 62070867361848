import { AppWrapper } from '@perchinteractiveinc/react-template-lb'
import { LangProvider } from 'context'

import './src/styles/global.css'

export const wrapRootElement = ({ element }) => (
	<LangProvider>
		<AppWrapper>
			{element}
		</AppWrapper>
	</LangProvider>
)
