import { getImage } from 'gatsby-plugin-image'
import {
	AttractPageQuery,
	AllCollectionQuery,
	Collection,
	Product,
	i18nProducts,
	i18nCollection,
	i18nCollections,
	AllProductsQuery
} from 'interfaces'
import { strapiImage } from 'interfaces/bussiness-logic'
import { useMemo } from 'react'

export const getProductsLocale = (data: AllProductsQuery): i18nProducts => {
	const { nodes } = data.products
	const localeData: i18nProducts = {} as i18nProducts
	nodes.forEach((prod: Product) => {
		prod.parsedImage = getGatsbyImage(prod.image)
		prod.parsedIcons = prod.product_icons?.map(icon => ({
			caption: icon.caption,
			image: getImage(icon.localFile)
		}))
		localeData[prod.locale]
			? localeData[prod.locale].push(prod)
			: (localeData[prod.locale] = [prod])
	})
	return localeData
}

export const getCollectionsLocale = (
	data: AttractPageQuery
): i18nCollections => {
	const { nodes } = data.collections
	const localeData: i18nCollections = {} as i18nCollections
	nodes.forEach((col: Collection) => {
		col.parsedImage = getGatsbyImage(col.product_images)
		col.products.forEach(prod => {
			prod.parsedImage = getGatsbyImage(prod.image)
			prod.parsedIcons = prod.product_icons?.map(icon => ({
				caption: icon.caption,
				image: getImage(icon.localFile)
			}))
		})
		localeData[col.locale]
			? pushCollection(localeData, col)
			: (localeData[col.locale] = [col])
	})

	return localeData
}

const pushCollection = (localeData: i18nCollections, col: Collection) => {
	col.collection_uid === 'מומלצים'
		? localeData[col.locale].unshift(col)
		: localeData[col.locale].push(col)
}

export const getCollectionLocale = (
	data: AllCollectionQuery
): i18nCollection => {
	const { nodes } = data.collection
	const localeData: i18nCollection = {} as i18nCollection
	nodes.forEach((col: Collection) => {
		col.products.forEach(
			prod => (prod.parsedImage = getGatsbyImage(prod.image))
		)
		localeData[col.locale] = col
	})
	return localeData
}

const getGatsbyImage = (image: strapiImage) => {
	if (!image) {
		return undefined
	}
	return getImage(image.localFile?.childImageSharp.gatsbyImageData)
}

export const useEngProducts = (collectionsData: i18nCollections) => {
	const allEngProducts = useMemo(() => {
		return collectionsData['en'].reduce<Product[]>((products, collection) => {
			products = [...products, ...collection.products]
			return products
		}, [])
	}, [collectionsData])
	return allEngProducts
}

export const useEngCollections = (collectionsData: i18nCollections) => {
	const allEngProducts = useMemo(() => {
		return collectionsData['en']
	}, [collectionsData])
	return allEngProducts
}
